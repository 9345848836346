define('ember-metrics/metrics-adapters/facebook-pixel', ['exports', 'ember', 'ember-metrics/utils/can-use-dom', 'ember-metrics/utils/object-transforms', 'ember-metrics/metrics-adapters/base'], function (exports, _ember, _emberMetricsUtilsCanUseDom, _emberMetricsUtilsObjectTransforms, _emberMetricsMetricsAdaptersBase) {
  var assert = _ember['default'].assert;
  var $ = _ember['default'].$;
  var get = _ember['default'].get;
  var compact = _emberMetricsUtilsObjectTransforms['default'].compact;
  exports['default'] = _emberMetricsMetricsAdaptersBase['default'].extend({
    toStringExtension: function toStringExtension() {
      return 'FacebookPixel';
    },

    init: function init() {
      var config = get(this, 'config');
      var id = config.id;

      assert('[ember-metrics] You must pass a valid `id` to the ' + this.toString() + ' adapter', id);

      if (_emberMetricsUtilsCanUseDom['default']) {
        /* jshint ignore:start */
        !(function (f, b, e, v, n, t, s) {
          if (f.fbq) return;n = f.fbq = function () {
            n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
          };if (!f._fbq) f._fbq = n;
          n.push = n;n.loaded = !0;n.version = '2.0';n.queue = [];t = b.createElement(e);t.async = !0;
          t.src = v;s = b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t, s);
        })(window, document, 'script', '//connect.facebook.net/en_US/fbevents.js');
        /* jshint ignore:end */

        window.fbq('init', id);

        // Leave this call due to Facebook API docs
        // https://developers.facebook.com/docs/facebook-pixel/api-reference#setup
        this.trackEvent({ event: 'PageView' });
      }
    },

    trackEvent: function trackEvent() {
      var options = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];

      if (!_emberMetricsUtilsCanUseDom['default']) {
        return;
      }

      var compactedOptions = compact(options);
      var event = compactedOptions.event;

      if (!event) {
        return;
      }
      delete compactedOptions.event;

      if (window.fbq) {
        window.fbq('track', event, compactedOptions);
      }
    },

    trackPage: function trackPage() {
      var options = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];

      if (!_emberMetricsUtilsCanUseDom['default']) {
        return;
      }

      window.fbq('track', 'PageView', options);
    },

    willDestroy: function willDestroy() {
      if (!_emberMetricsUtilsCanUseDom['default']) {
        return;
      }

      $('script[src*="fbevents.js"]').remove();
      delete window.fbq;
    }
  });
});