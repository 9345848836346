define('ember-hubspot-form/components/hubspot-form', ['exports', 'ember', 'ember-hubspot-form/templates/components/hubspot-form'], function (exports, _ember, _emberHubspotFormTemplatesComponentsHubspotForm) {
  var _get = _ember['default'].get;
  var isPresent = _ember['default'].isPresent;
  var computed = _ember['default'].computed;
  var warn = _ember['default'].Logger.warn;
  exports['default'] = _ember['default'].Component.extend({

    layout: _emberHubspotFormTemplatesComponentsHubspotForm['default'],

    hasValidFormConfig: computed('portalId', 'formId', {
      get: function get() {
        return isPresent(_get(this, 'portalId')) && isPresent(_get(this, 'formId'));
      }
    }),

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      if (!_get(this, 'hasValidFormConfig')) {
        warn('Ember Hubspot Form could not be inserted: it requires a valid `portalId` and `formId`. ');
      }
    },

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      // DOM selector to insert form into
      var target = _get(this, 'target') || '#' + _get(this, 'elementId');

      if (_get(this, 'hasValidFormConfig')) {

        hbspt.forms.create({
          portalId: _get(this, 'portalId'),
          formId: _get(this, 'formId'),
          target: target
        });
      }
    }
  });
});
/* global hbspt */