define('ember-metrics/metrics-adapters/google-tag-manager', ['exports', 'ember', 'ember-metrics/utils/can-use-dom', 'ember-metrics/utils/object-transforms', 'ember-metrics/metrics-adapters/base'], function (exports, _ember, _emberMetricsUtilsCanUseDom, _emberMetricsUtilsObjectTransforms, _emberMetricsMetricsAdaptersBase) {
  var assert = _ember['default'].assert;
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var $ = _ember['default'].$;
  var getWithDefault = _ember['default'].getWithDefault;
  var capitalize = _ember['default'].String.capitalize;

  var assign = _ember['default'].assign || _ember['default'].merge;
  var compact = _emberMetricsUtilsObjectTransforms['default'].compact;
  exports['default'] = _emberMetricsMetricsAdaptersBase['default'].extend({
    dataLayer: 'dataLayer',

    toStringExtension: function toStringExtension() {
      return 'GoogleTagManager';
    },

    init: function init() {
      var config = get(this, 'config');
      var id = config.id;

      var dataLayer = getWithDefault(config, 'dataLayer', 'dataLayer');

      assert('[ember-metrics] You must pass a valid `id` to the ' + this.toString() + ' adapter', id);

      set(this, 'dataLayer', dataLayer);

      if (_emberMetricsUtilsCanUseDom['default']) {
        /* jshint ignore:start */
        (function (w, d, s, l, i) {
          w[l] = w[l] || [];w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });var f = d.getElementsByTagName(s)[0],
              j = d.createElement(s),
              dl = l != 'dataLayer' ? '&l=' + l : '';j.async = true;j.src = '//www.googletagmanager.com/gtm.js?id=' + i + dl;f.parentNode.insertBefore(j, f);
        })(window, document, 'script', get(this, 'dataLayer'), id);
        /* jshint ignore:end */
      }
    },

    trackEvent: function trackEvent() {
      var options = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];

      var compactedOptions = compact(options);
      var dataLayer = get(this, 'dataLayer');
      var gtmEvent = { 'event': compactedOptions['event'] };

      delete compactedOptions['event'];

      for (var key in compactedOptions) {
        var capitalizedKey = capitalize(key);
        gtmEvent['event' + capitalizedKey] = compactedOptions[key];
      }

      if (_emberMetricsUtilsCanUseDom['default']) {
        window[dataLayer].push(gtmEvent);
      }

      return gtmEvent;
    },

    trackPage: function trackPage() {
      var options = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];

      var compactedOptions = compact(options);
      var dataLayer = get(this, 'dataLayer');
      var sendEvent = {
        event: compactedOptions['event'] || 'pageview'
      };

      var pageEvent = assign(sendEvent, compactedOptions);

      if (_emberMetricsUtilsCanUseDom['default']) {
        window[dataLayer].push(pageEvent);
      }

      return pageEvent;
    },

    willDestroy: function willDestroy() {
      if (_emberMetricsUtilsCanUseDom['default']) {
        $('script[src*="gtm.js"]').remove();
        delete window.dataLayer;
      }
    }
  });
});