define('ember-cli-media-queries/services/media-queries', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Service.extend({
    matches: null,
    matcher: window.matchMedia,
    media: null, // Object hash of media query rules -- to be extended

    _matchers: null,

    classNames: _ember['default'].computed('matches.[]', function () {
      var transformFunc = this.get('classNameFromQueryKey').bind(this);
      return this.get('matches').map(transformFunc).join(' ');
    }),

    classNameFromQueryKey: function classNameFromQueryKey(key) {
      return 'media-' + _ember['default'].String.dasherize(key);
    },

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var media = this.get('media');
      _ember['default'].assert('ember-cli-media-queries: `media` must be overridden as a JavaScript object in media-queries service', _ember['default'].typeOf(media) === 'object');

      this.set('matches', _ember['default'].A());

      var matchers = {};
      var matcher = this.get('matcher') || window.matchMedia;
      var isMatcherFunction = typeof matcher === 'function';

      Object.keys(media).forEach(function (key) {
        var isMediaKey = 'is' + _ember['default'].String.classify(key);

        if (!isMatcherFunction) {
          // matcher function is missing e.g. FastBoot
          _this.set(isMediaKey, false);
          return;
        }

        var query = media[key];
        var mediaQueryList = matcher(query);

        var listener = _ember['default'].run.bind(_this, function (mql) {
          this.set(isMediaKey, mql.matches);
          if (mql.matches) {
            this.get('matches').pushObject(key);
          } else {
            this.get('matches').removeObject(key);
          }
        });

        if (mediaQueryList.addListener) {
          mediaQueryList.addListener(listener);
          matchers[key] = {
            mediaQueryList: mediaQueryList,
            listener: listener
          };
        }

        listener(mediaQueryList);
      });

      this.set('_matchers', matchers);
    },

    emulate: function emulate() {
      var _this2 = this;

      for (var _len = arguments.length, mediaTypes = Array(_len), _key = 0; _key < _len; _key++) {
        mediaTypes[_key] = arguments[_key];
      }

      var media = this.get('media');
      var matches = [];
      Object.keys(media).forEach(function (key) {
        var isMediaKey = 'is' + _ember['default'].String.classify(key);
        var isMediaMatch = mediaTypes.indexOf(key) !== -1;
        _this2.set(isMediaKey, isMediaMatch);
        if (isMediaMatch) {
          matches.push(key);
        }
      });
      this.get('matches').setObjects(matches);
    },

    willDestroy: function willDestroy() {
      var matchers = this.get('_matchers');
      if (matchers) {
        Object.keys(matchers).forEach(function (key) {
          matchers[key].mediaQueryList.removeListener(matchers[key].listener);
        });
      }

      return this._super.apply(this, arguments);
    }
  });
});