define('ember-metrics/metrics-adapters/google-analytics', ['exports', 'ember', 'ember-metrics/utils/can-use-dom', 'ember-metrics/utils/object-transforms', 'ember-metrics/metrics-adapters/base'], function (exports, _ember, _emberMetricsUtilsCanUseDom, _emberMetricsUtilsObjectTransforms, _emberMetricsMetricsAdaptersBase) {
  var isPresent = _ember['default'].isPresent;
  var copy = _ember['default'].copy;
  var assert = _ember['default'].assert;
  var get = _ember['default'].get;
  var $ = _ember['default'].$;
  var capitalize = _ember['default'].String.capitalize;
  var compact = _emberMetricsUtilsObjectTransforms['default'].compact;

  var assign = _ember['default'].assign || _ember['default'].merge;

  exports['default'] = _emberMetricsMetricsAdaptersBase['default'].extend({
    toStringExtension: function toStringExtension() {
      return 'GoogleAnalytics';
    },

    init: function init() {
      var config = copy(get(this, 'config'));
      var id = config.id;

      assert('[ember-metrics] You must pass a valid `id` to the ' + this.toString() + ' adapter', id);

      delete config.id;

      var hasOptions = isPresent(Object.keys(config));

      if (_emberMetricsUtilsCanUseDom['default']) {
        /* jshint ignore:start */
        (function (i, s, o, g, r, a, m) {
          i['GoogleAnalyticsObject'] = r;i[r] = i[r] || function () {
            (i[r].q = i[r].q || []).push(arguments);
          }, i[r].l = 1 * new Date();a = s.createElement(o), m = s.getElementsByTagName(o)[0];a.async = 1;a.src = g;m.parentNode.insertBefore(a, m);
        })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');
        /* jshint ignore:end */

        if (hasOptions) {
          window.ga('create', id, config);
        } else {
          window.ga('create', id, 'auto');
        }
      }
    },

    identify: function identify() {
      var options = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];

      var compactedOptions = compact(options);
      var distinctId = compactedOptions.distinctId;

      if (_emberMetricsUtilsCanUseDom['default']) {
        window.ga('set', 'userId', distinctId);
      }
    },

    trackEvent: function trackEvent() {
      var options = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];

      var compactedOptions = compact(options);
      var sendEvent = { hitType: 'event' };
      var gaEvent = {};

      if (compactedOptions.nonInteraction) {
        gaEvent.nonInteraction = compactedOptions.nonInteraction;
        delete compactedOptions.nonInteraction;
      }

      for (var key in compactedOptions) {
        var capitalizedKey = capitalize(key);
        gaEvent['event' + capitalizedKey] = compactedOptions[key];
      }

      var event = assign(sendEvent, gaEvent);
      if (_emberMetricsUtilsCanUseDom['default']) {
        window.ga('send', event);
      }

      return event;
    },

    trackPage: function trackPage() {
      var options = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];

      var compactedOptions = compact(options);
      var sendEvent = { hitType: 'pageview' };

      var event = assign(sendEvent, compactedOptions);
      if (_emberMetricsUtilsCanUseDom['default']) {
        window.ga('send', event);
      }

      return event;
    },

    willDestroy: function willDestroy() {
      if (_emberMetricsUtilsCanUseDom['default']) {
        $('script[src*="google-analytics"]').remove();
        delete window.ga;
      }
    }
  });
});